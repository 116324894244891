@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

.RatingStars svg {
  display: inline;
}
